<template>
  <div style="background:#cfd1cc">
    <lj-nav></lj-nav>
    <router-view />
    <lj-footer></lj-footer>
  </div>
</template>

<script>
import LjFooter from './../../../components/footer.vue'
import LjNav from './../../../components/nav.vue'
export default {
  data: function () {
    return {}
  },
  components: {
    LjFooter,
    LjNav
  },
  methods: {}
}
</script>

<style lang="less" scoped>
@import url('./../../../Public/resources/css/templates/home/major/std_result.min.css');
@import url('./../../../Public/resources/css/templates/home/major/std_result_index.min.css');
@import url('./../../../Public/resources/css/templates/home/major/index.min.css');
@import url('./../../../Public/resources/css/components/form.min.css');

.main {
  background: #cfd1cc;
  color: #000;
}
</style>
